// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import JQuery from 'jquery';
window.$ = window.jQuery = JQuery;
const Popper = require('popper.js').default;
import 'bootstrap/dist/js/bootstrap';

require('jquery-ui');

import intlTelInput from 'intl-tel-input';
window.intlTelInput = intlTelInput;
require('../libs/jquery.caret');
require('jquery.mobilephonenumber');

require('../theme/app');
require('../theme/app.init');

require('../pages/authentication');

const images = require.context('../images', true);

require('stylesheets/theme/leagues');
require('stylesheets/theme/custom/pages/_form-pages');
require('stylesheets/theme/custom/pages/_authentication');
require('intl-tel-input/build/css/intlTelInput');