var phoneContries = [
  "US",
  "BR",
  "MX",
  "CA",
  "IN",
  "CN",
  "DE",
  "FR",
  "ES",
  "AT",
  "NZ",
  "ZA",
  "IE",
  "IS",
];

$(document).on("click", "#change-method", function (e) {
  var thisForm = "#loginform-" + $(this).data("login-method");
  $(".form-horizontal:not(" + thisForm + ")")
    .removeClass("d-none")
    .addClass("form-material");
  $(thisForm).addClass("d-none").removeClass("form-material");
  e.preventDefault();
});

$(document).on("turbolinks:load", function () {
  const setPhone = (input) => {
    window.intlTelInput(input, {
      dropdownContainer: document.querySelector("body"),
      customContainer: "w-100",
      onlyCountries: phoneContries,
      utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.3/build/js/utils.js",
    });
    var input = $(".iti__flag-container + input");
    input.mobilePhoneNumber();
  };

  var inputs = document.querySelectorAll("#loginform-phone #auth_value, #user_phone");
  for (let i = 0; i < inputs.length; i++) setPhone(inputs[i]);

  $(".password-toggle-group i").on("click", function () {
    $(this).toggleClass("mdi-eye-off-outline mdi-eye");
    var input = $(this).parent().find("input");
    if (input.attr("type") === "password") {
      input.attr("type", "text");
    } else {
      input.attr("type", "password");
    }
  });

  setTimeout(() => $("#user_phone").val($("#user_phone").attr("value")), 1000);
  setTimeout(() => {
    if ($("#loginform-phone #auth_value").length === 0) return;

    $("#loginform-phone #auth_value").val($("#loginform-phone #auth_value").attr("value"));
    var input = $(".iti__flag-container + input");
    input.mobilePhoneNumber();
  }, 1000);
});
